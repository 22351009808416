<template>
  <b-modal
    :id="idModal"
    centered
    :title="titulo"
    :ok-title="textButtonConfirm"
    ok-variant="danger"
    cancel-ritle="Voltar"
    cancel-variant="outline-secondary"
    :item-nome="itemNome"
    :item-id="itemId"
    :no-close-on-backdrop="true"
    @ok="onOk"
  >
    <b-card-text>
      {{ itemNome }} ? <br />
      {{ textSecondary }}
    </b-card-text>
  </b-modal>
</template>

<script>
import { BCardText, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardText,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    titulo: {
      type: String,
      required: true,
      default: 'Confimar Ação',
    },
    itemNome: {
      type: String,
      default: 'Nome Item',
    },
    itemId: {
      type: String,
      default: '',
    },
    textButtonConfirm: {
      type: String,
      default: 'Excluir',
    },
    idModal: {
      type: String,
      default: 'modal-confirmacao',
    },
    textSecondary: {
      type: String,
      default: '',
    },
  },

  methods: {
    onOk() {
      this.$emit('itemConfirmado', { id: this.itemId })
    },
  },
}
</script>
