import { apenasNumeros } from '@/@core/utils/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    fetchClientes({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/clientes', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    fetchEmpresas() {
      return new Promise((resolve, reject) => {
        axios
          .get('/empresas')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => console.log(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`clientes/${item.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    store(ctx, userData) {
      const formData = new FormData()

      formData.append('razao_social', userData.razao_social)
      formData.append('email', userData.email)
      formData.append('telefone', userData.telefone)
      formData.append('nome_relatorio', userData.nome_relatorio)

      formData.append('contato_responsavel', userData.contato_responsavel)
      if (userData.cpf) {
        formData.append('cpf', userData.cpf)
      }
      if (userData.cnpj) {
        formData.append('cnpj', userData.cnpj)
      }

      Object.keys(userData.endereco)
        .forEach(chave => {
          formData.append(`endereco[${chave}]`, userData.endereco[chave])
        })
      Object.keys(userData.empresas)
        .forEach(chave => {
          formData.append(`empresas[${chave}]`, userData.empresas[chave])
        })

      if (userData.logo) {
        formData.append('imagem', userData.logo)
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/clientes', formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, userData) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        formData.append('razao_social', userData.razao_social)
        formData.append('email', userData.email)
        formData.append('telefone', apenasNumeros(userData.telefone))
        formData.append('nome_relatorio', userData.nome_relatorio)

        formData.append('uuid', userData.uuid)
        formData.append('contato_responsavel', userData.contato_responsavel)

        if (userData.cpf) {
          formData.append('cpf', userData.cpf)
        }
        if (userData.cnpj) {
          formData.append('cnpj', userData.cnpj)
        }

        Object.keys(userData.empresas)
          .forEach(chave => {
            formData.append(`empresas[${chave}]`, userData.empresas[chave])
          })

        Object.keys(userData.endereco)
          .forEach(chave => {
            formData.append(`endereco[${chave}]`, userData.endereco[chave])
          })

        if (userData.logo) {
          formData.append('imagem', userData.logo)
        }

        formData.append('_method', 'PUT')

        axios
          .post(`clientes/${userData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteCliente(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/clientes/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },
  },
}
