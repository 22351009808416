<template>
  <div>
    <form-create
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :empresa-options="empresaOptions"
      titulo="Novo Cliente"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
              <b-button
                variant="success"
                @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refItemListTable"
        class="position-relative"
        :items="fetchClientes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoad"
        striped
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <!-- Column: Razao Social -->
        <template #cell(razao_social)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="4.5em"
                :src="data.item.imagem"
                :text="avatarText(data.item.razao_social)"
                :variant="'secondary'"
                class="avatar-with-shadow"
              />
            </template>
            <div><small>
              <TooltipTextoCortado
                :texto="data.item.razao_social"
                :limite="25"
              />
            </small></div>
          </b-media>
        </template>
        <template #cell(cnpj)="data">
          <small>{{ data.item.cnpj ? data.item.cnpj : data.item.cpf }}</small>
        </template>
        <!-- Column: Endereço -->
        <template #cell(endereco)="data">

          <b-media
            v-if="data.item.endereco"
            vertical-align="center"
          >

            <small> {{ data.item.endereco.logradouro }} , {{ data.item.endereco.num_endereco }}</small>
            <small class="d-block">{{ data.item.endereco.cidade }} / {{ data.item.endereco.estado }}</small>

          </b-media>
        </template>
        <!-- Column: Empresas -->
        <template #cell(empresas)="data">
          <b-media vertical-align="center">

            <div
              v-for="(item, index) in data.item.empresas"
              :key="index"
            >
              <small>{{ item.nome }}</small>
            </div>

          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
            v-if="$can('atualizar', 'clientes')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'clientes-edit', params: { id: data.item.uuid } }">

              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItens"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir Item"
        :item-id="dadosCliente.id"
        :item-nome="dadosCliente.razao_social"
        @itemConfirmado="excluirItem"
        @click="modalExcluir(data.item)"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { avatarText } from '@core/utils/filter'
import { onUnmounted, ref } from '@vue/composition-api'
import FormCreate from './FormCreate.vue'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import store from '@/store'
import TooltipTextoCortado from '@/views/components/componentesPensou/TooltipTextoCortado.vue'
import clienteStoreModule from '../clienteStoreModule'
import useClientesList from './useClientesList'
import UsersListFilters from './UsersListFilters.vue'

export default {
  components: {
    UsersListFilters,
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    TooltipTextoCortado,
  },
  setup() {
    const CLIENTE_APP_STORE_MODULE_NAME = 'app-clientes'

    // Register module
    if (!store.hasModule(CLIENTE_APP_STORE_MODULE_NAME)) store.registerModule(CLIENTE_APP_STORE_MODULE_NAME, clienteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENTE_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENTE_APP_STORE_MODULE_NAME)
    })

    const isAddNewSidebarActive = ref(false)

    const empresaOptions = ref([])
    store.dispatch('app-clientes/fetchEmpresas')
      .then(response => {
        empresaOptions.value = response.data
      })

    const {
      fetchClientes,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemListTable,
      refetchData,
      deleteCliente,
    } = useClientesList()

    return {
      isAddNewSidebarActive,
      deleteCliente,
      fetchClientes,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemListTable,
      refetchData,
      avatarText,
      empresaOptions,

    }
  },
  data() {
    return {

      dadosCliente: {
        id: '',
        razao_social: '',
      },
    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-clientes'].load
    },
  },

  methods: {
    modalExcluir(item) {
      this.dadosCliente.razao_social = item.razao_social
      this.dadosCliente.id = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      // Metodos atribuidos como const no setup podem ser consumidos com o this
      this.deleteCliente(dados)
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.avatar-with-shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.media {
  display: flex;
  align-items: center;
}
</style>
