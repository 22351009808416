<template>
  <div v-if="textoCompleto.length > limite" v-b-tooltip.hover.top.v-primary :title="getTextoCompleto"
    class="cursor-pointer texto-cortado">
    {{ getTextoTooltip }}
  </div>
  <div v-else>{{ getTextoCompleto }}</div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
export default {

  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    texto: String,
    limite: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      textoCortado: this.texto.substring(0, this.limite),
      textoCompleto: this.texto,


    };
  },

  computed: {
    getTextoTooltip() {
      return this.textoCompleto.length > this.limite ? `${this.textoCortado}...` : this.textoCortado
    },
    getTextoCompleto() {
      return this.textoCompleto
    }

  },
  watch: {
    texto(newTexto) {
      this.textoCompleto = newTexto;
      this.textoCortado = newTexto.substring(0, this.limite);
    }
  },

};
</script>

<style scoped>
.texto-cortado {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: fit-content;
}
</style>